



















































import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";

// Components
import FabTop from "@/components/FabTop.vue";

@Component({
    metaInfo(): MetaInfo {
        return {
            title: "Coming Soon",
            titleTemplate: '%s | REPTEX'
        };
    },

    components: {
        FabTop,
        Loader: () => import('@/components/Loader.vue')
    }
})
export default class extends Vue {
    loading = true;

    social = [
        { name: 'Facebook', icon: 'mdi-facebook', color: 'blue darken-3', url: 'https://www.facebook.com/ReptexMX/' },
        { name: 'Instagram', icon: 'mdi-instagram', color: 'pink darken-1', url: 'https://www.instagram.com/reptex.mx/' },
    ];

    created() {
        window.addEventListener('load', this.hideLoader);
    }

    public hideLoader(): void {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    }
}
