









































import { MetaInfo } from "vue-meta";
import { Component, Mixins } from "vue-property-decorator";

// Mixins
import BreakpointMixin from "@/mixins/BreakpointMixin";

@Component({
    metaInfo(): MetaInfo {
        return {
            title: 'Coming Soon'
        };
    },

    components: {
        CountdownArea: () => import('@/components/CountdownArea.vue'),
        Anniversary: () => import('@/components/Anniversary.vue')
    }
})
export default class ComingSoon extends Mixins(BreakpointMixin) {
    get pathSVG(): string {
        if (this.isMobile()) {
            return 'M0,288L120,272C240,256,480,224,720,224C960,224,1200,256,1320,272L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z';
        }

        return 'M0,224L34.3,240C68.6,256,137,288,206,261.3C274.3,235,343,149,411,96C480,43,549,21,617,26.7C685.7,32,754,64,823,112C891.4,160,960,224,1029,256C1097.1,288,1166,288,1234,266.7C1302.9,245,1371,203,1406,181.3L1440,160L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z';
    }
}

